$primary_background_color: #538d9c;
$primary_background_color_alt: #80b0bb;
$primary_text_color: #ffffff;
$primary_text_color_alt: #ffffff;

label.descriptionLabel {
  font-size: 14px;
  line-height: 1.42857143;
  color: #655655;
  max-width: 100%;
  margin-top: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}

.centerContent {
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
}

.pullRight {
  float: right !important;
  margin-bottom: 5px;
}

button[class^="button-module_primary"].generateDescriptionButton {
  background-color: $primary_background_color;
  border-color: $primary_background_color;
  color: $primary_text_color;
  font-family: inherit;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 5px 10px;
  &:disabled {
    background-color: $primary_background_color;
    border-color: $primary_background_color;
    color: $primary_text_color;
    opacity: 0.4;
    pointer-events: none;
  }
  &:focus {
    background-color: $primary_background_color;
    border-color: $primary_background_color;
    color: $primary_text_color;
    outline: none;
    box-shadow: none;
  }
  &:not(:disabled) {
    &:active {
      background-color: $primary_background_color;
      border-color: $primary_background_color;
      color: $primary_text_color;
      outline: none;
      box-shadow: none;
    }
    &:hover {
      background-color: $primary_background_color_alt;
      border-color: $primary_background_color_alt;
    }
  }
}

.descriptionTextBox {
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border: 1px solid #cccccc;
  color: #555555;
  border-radius: 2px;
  box-shadow: none;
  &:read-only {
    background-color: #eeeeee;
  }
  &:focus {
    border-color: #fdb415;
    outline: 0;
  }
}

.copyIcon {
  filter: invert(100%);
  margin-right: 5px;;
}

button[class^="button-module_secondary"].copyDescriptionButton {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  font-family: inherit;
  outline: none !important;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  user-select: none;
  color: #333;
  background-color: #fff;
  float: right !important;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.15),0 1px 1px rgba(0,0,0,0.075);
  background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
  background-repeat: repeat-x;
  text-shadow: 0 1px 0 #fff;
  border-color: #ccc;
  margin-left: 2px;
  &:disabled {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    font-family: inherit;
    outline: none !important;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 2px;
    user-select: none;
    color: #333;
    float: right !important;
    background-repeat: repeat-x;
    text-shadow: 0 1px 0 #fff;
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
    box-shadow: none;
    border-color: #ccc;
    background-color: #e0e0e0;
    background-image: none;
    margin-left: 2px;
  }
  &:focus {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    font-family: inherit;
    outline: none !important;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 2px;
    user-select: none;
    float: right !important;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.15),0 1px 1px rgba(0,0,0,0.075);
    background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
    background-repeat: repeat-x;
    text-shadow: 0 1px 0 #fff;
    outline-offset: -2px;
    text-decoration: none;
    color: #333;
    border-color: #adadad;
    background-color: #e0e0e0;
    background-position: 0 -15px;
    margin-left: 2px;
  }
  &:not(:disabled) {
    &:active {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      box-sizing: border-box;
      font: inherit;
      margin: 0;
      overflow: visible;
      text-transform: none;
      -webkit-appearance: button;
      font-family: inherit;
      outline: none !important;
      display: inline-block;
      margin-bottom: 0;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 2px;
      user-select: none;
      float: right !important;
      background-repeat: repeat-x;
      text-shadow: 0 1px 0 #fff;
      color: #333;
      box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
      background-image: none;
      background-color: #e0e0e0;
      border-color: #dbdbdb;
      margin-left: 2px;
    }
    &:hover {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      box-sizing: border-box;
      font: inherit;
      margin: 0;
      overflow: visible;
      text-transform: none;
      -webkit-appearance: button;
      font-family: inherit;
      outline: none !important;
      display: inline-block;
      margin-bottom: 0;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 2px;
      user-select: none;
      float: right !important;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.15),0 1px 1px rgba(0,0,0,0.075);
      background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
      background-repeat: repeat-x;
      text-shadow: 0 1px 0 #fff;
      text-decoration: none;
      color: #333;
      border-color: #adadad;
      background-color: #e0e0e0;
      background-position: 0 -15px;
      margin-left: 2px;
    }
  }
}
